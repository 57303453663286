import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createBetaFlags } from '@insights-ltd/design-library';
import { TFunctionDetailedResult } from 'i18next';
import { $SpecialObject } from 'i18next/typescript/helpers';

export const BETA_DEFAULT = false;

const PRODUCTION_HOSTNAME = 'practitioner.insights.com';

export const getBetaAvailable = (): boolean => {
  return window.location.hostname !== PRODUCTION_HOSTNAME;
};

/*
When adding a new beta feature, add its name here.
When releasing a beta feature to production, remove it from here and TypeScript
will fail the build until every usage of it is removed.
 */
export type FEATURE =
  | 'default-3drs'
  | 'learner-search-performance'
  | 'data-retention-feature'
  | 'supported-languages';

export const { useBetaEnabled } = createBetaFlags<FEATURE>();

export const useIsBetaPageActive = (
  feature: FEATURE,
  config?: { redirectURL: string },
): void => {
  const isPageEnabled = useBetaEnabled(feature);
  const redirectUrl = config?.redirectURL ? config.redirectURL : '/';
  const navigate = useNavigate();

  if (!isPageEnabled) {
    navigate(redirectUrl);
  }
};

export const useBetaTranslation = (
  feature: FEATURE,
): ((
  key: any,
  options: any,
) =>
  | string
  | $SpecialObject
  | TFunctionDetailedResult<string | $SpecialObject, any>) => {
  const isBetaEnabled = useBetaEnabled(feature);
  const { t } = useTranslation();
  return (key: any, options: any) => {
    const suffix = isBetaEnabled ? '.beta' : '';
    return t(key + suffix, options);
  };
};
