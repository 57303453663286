import {
  _delete,
  get,
  post,
  put,
} from '@insights-ltd/design-library/src/utils/mocking';
import { API_ROOT } from 'mocks/handlers/common';
import { GetGroupResponse, Group, GroupResponse, RequestErrorBody } from 'api';

export const organisationGroups = {
  search: put<GetGroupResponse | RequestErrorBody>(
    `${API_ROOT}/organisation-groups`,
  ),
  all: get<GetGroupResponse>(`${API_ROOT}/organisation-groups`),
  create: post<Group | RequestErrorBody>(`${API_ROOT}/organisation-groups`),
  update: (organisationGroupId: string) =>
    put<undefined>(`${API_ROOT}/organisation-groups/${organisationGroupId}`),
  delete: (organisationGroupId: string) =>
    _delete<undefined>(
      `${API_ROOT}/organisation-groups/${organisationGroupId}`,
    ),
  get: (organisationGroupId: string) =>
    get<GroupResponse>(
      `${API_ROOT}/organisation-groups/${organisationGroupId}`,
    ),
  getExistingUser: (groupId: string) =>
    get<any>(`${API_ROOT}/organisation-groups/${groupId}/practitioners`),
  addExistingPractitioner: (groupId: string, practitionerId: string) =>
    post(
      `${API_ROOT}/organisation-groups/${groupId}/practitioners/${practitionerId}/organisations`,
    ),
  organisations: {
    addToGroup: (organisationGroupId: string, organisationId: string) =>
      post<undefined | RequestErrorBody>(
        `${API_ROOT}/organisation-groups/${organisationGroupId}/organisations/${organisationId}`,
      ),
    removeFromGroup: (organisationGroupId: string, organisationId: string) =>
      _delete<undefined | RequestErrorBody>(
        `${API_ROOT}/organisation-groups/${organisationGroupId}/organisations/${organisationId}`,
      ),
    create: (groupId: string) =>
      post<
        | {
            id: string;
            name: string;
          }
        | RequestErrorBody
      >(`${API_ROOT}/organisation-groups/${groupId}/organisations`),
  },
};
