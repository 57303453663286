import React, { useState } from 'react';
import {
  Group,
  OrganisationResponse,
  OrganisationSupportedRole,
} from 'api/httpEntities';
import OrganisationList from 'components/OrganisationList';
import { Box, Container, Grid, styled } from '@mui/material';
import { H2, P, PaginationControls } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/OrganisationSearchBox';
import { isAdvancedUser } from 'utils/role';
import { User } from 'contexts/AuthContext';
import usePagination from '@insights-ltd/design-library/src/components/hooks/usePagination';

type Props = {
  organisations: OrganisationResponse[];
  group: Group;
  user?: User;
  supportedRoles: OrganisationSupportedRole[];
};

interface StyledSpanProps {
  hasOrgs?: boolean;
}

const StyledSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'hasOrgs',
})<StyledSpanProps>(({ theme, hasOrgs }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.common.white,
  backgroundColor: hasOrgs
    ? theme.palette.pink.main
    : `${theme.palette.grey[500]} !important`,
  borderRadius: '4px',
}));

const GroupOrganisationList = ({
  organisations,
  group,
  user,
  supportedRoles,
}: Props) => {
  const isAU = isAdvancedUser(user?.roles ?? []);
  const OrganisationLabels: { all: string; filtered: string } = {
    all: isAU
      ? 'ui.event-management.group.my-organisations'
      : 'ui.event-management.group.total-organisations',
    filtered: 'ui.event-management.group.search-results',
  };
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const isSearching = searchText !== '';
  const filteredOrgs = isSearching
    ? organisations.filter((organisation: OrganisationResponse) =>
        organisation.name.toLowerCase().includes(searchText.toLowerCase()),
      )
    : organisations;

  const title =
    searchText.length === 0
      ? t(OrganisationLabels.all)
      : t(OrganisationLabels.filtered);
  const displayCount = isSearching ? filteredOrgs.length : organisations.length;

  const hasOrgs = organisations.length > 0;

  const paginator = usePagination(filteredOrgs, 9);
  const { pagedItems, handlePageChange, pageCount } = paginator;

  const onSearchTextChanges = (value: string) => {
    setSearchText(value);
    handlePageChange(1);
  };

  return (
    <Container
      sx={(theme) => ({
        padding: `${theme.spacing(spacingSizeMap.M)} 0 ${theme.spacing(
          spacingSizeMap.XL,
        )}`,
      })}
    >
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: '14px' }}
        >
          <Grid item xs={12} md="auto">
            <H2 variant="h3">
              {title}{' '}
              <StyledSpan data-testid="resultsNumber" hasOrgs={hasOrgs}>
                {displayCount}
              </StyledSpan>
            </H2>
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchBox
              placeholderKey="ui.event-management.group.search-results.placeholder"
              value={searchText}
              onChange={onSearchTextChanges}
            />
          </Grid>
        </Grid>
        <Grid>
          {pagedItems.length && pageCount > 1 ? (
            <PaginationControls itemsPerPage={9} show paginator={paginator} />
          ) : null}
        </Grid>
      </Box>
      {hasOrgs ? (
        <OrganisationList
          showBanners={false}
          organisations={pagedItems}
          group={group}
          supportedRoles={supportedRoles}
        />
      ) : (
        <P color="textSecondary">
          {t('ui.event-management.group.search-results.no-organisations')}
        </P>
      )}
    </Container>
  );
};

export default GroupOrganisationList;
