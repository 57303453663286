import TextField from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import { InputText, InputType, P, Span } from '@insights-ltd/design-library';
import { Trans, useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  styled,
  SxProps,
  useAutocomplete,
} from '@mui/material';
import { GroupType, OrganisationResponse } from 'api';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

const ErrorMessage = styled('p')(({ theme }) => ({
  ...theme.typography.caption,
  color: `${theme.palette.error.main}`,
  margin: '3px 14px 0 14px',
}));

type Props = {
  options?: OrganisationResponse[];
  onSelect: (value: OrganisationResponse) => void;
  loading: boolean;
  className?: string;
  disableClearable?: boolean;
  clearOnSelect?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
  error?: boolean;
  selectedValue?: string | null;
  disabled?: boolean;
  labelKey?: string;
  label?: string;
  inputSx?: SxProps;
  organisationsInGroups?: GroupOrganisationHashMap;
  dataTestId?: string;
};

interface TextFieldParamsProps extends AutocompleteRenderInputParams {
  InputLabelProps: Omit<
    ReturnType<ReturnType<typeof useAutocomplete>['getInputLabelProps']>,
    'children'
  >;
}

const StyledP = styled(P)({
  width: '50%',
  wordWrap: 'break-word',
});

const StyledSpan = styled(Span)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold} !important`,
}));

const StyledListItem = styled('li')({
  alignItems: 'flex-start !important',
});

export const GroupText = ({
  groupName,
  groupType,
}: {
  groupName: string;
  groupType: string;
}) => (
  <Trans
    i18nKey="ui.event-management.organisations.select-organisation.group.group-name"
    values={{ groupName, groupType }}
    components={{ '2': <StyledSpan color="textSecondary" variant="body" /> }}
  />
);

const StyledInput = styled(InputText)(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.palette.background.paper,
    height: '3rem',
    width: '100%',
  },
  marginRight: '1rem',
}));

function preventFormSubmission(event: React.KeyboardEvent<HTMLDivElement>) {
  if (event.key === 'Enter') event.preventDefault();
}

const OrganisationAutocomplete = ({
  options = [],
  onSelect,
  loading,
  disableClearable = false,
  clearOnSelect = false,
  required,
  error = false,
  className,
  selectedValue,
  helperText,
  disabled,
  label,
}: Props) => {
  const { t } = useTranslation();

  const getSelectedItem = () =>
    options?.length && selectedValue
      ? (options.find(({ id }) => id === selectedValue) as OrganisationResponse)
      : null;

  return (
    <div>
      {label && (
        <label htmlFor="organisation-search">
          <Span variant="body-bold">{label}</Span>
        </label>
      )}
      <Autocomplete
        className={className}
        id="organisation-search"
        data-testid="organisation-autocomplete"
        disableClearable={disableClearable}
        autoSelect={disableClearable}
        options={options}
        sx={{ marginTop: '0.5rem' }}
        fullWidth
        loadingText={t('ui.event-management.organisations.loading-text')}
        noOptionsText={t('ui.event-management.organisations.no-options-text')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value, reason) => {
          if (
            reason === ('selectOption' as AutocompleteChangeReason) &&
            value !== null
          ) {
            onSelect(value);
            if (clearOnSelect) {
              onSelect({
                id: '',
                name: '',
                dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
              });
            }
          }
        }}
        filterOptions={createFilterOptions({
          stringify: (option: OrganisationResponse) => `${option.name}`,
        })}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(fullProps, val) => {
          const { color, ...rest } = fullProps;
          return (
            <li
              {...rest}
              key={val.id}
              data-testid={`organisation-option-${val.id}`}
            >
              <Span variant="body-bold" color="primary">
                {val.name}
              </Span>
            </li>
          );
        }}
        value={getSelectedItem()}
        loading={loading}
        renderInput={(params: TextFieldParamsProps) => (
          <TextField
            {...params}
            label={t('ui.event-management.organisations.select-organisation')}
            placeholder={t(
              'ui.event-management.organisations.select-organisation-placeholder',
            )}
            variant="outlined"
            required={!!required}
            error={error}
            disabled={!!disabled}
            onKeyDown={preventFormSubmission}
          />
        )}
      />
      {helperText ? <ErrorMessage>{helperText}</ErrorMessage> : null}
    </div>
  );
};

export const OrganisationAutocompleteV2 = ({
  options = [],
  onSelect,
  loading,
  disableClearable = false,
  clearOnSelect = false,
  required,
  error = false,
  className,
  selectedValue,
  helperText,
  disabled,
  inputSx,
  labelKey = 'ui.event-management.organisations.select-organisation',
  organisationsInGroups = {},
  dataTestId,
}: Props) => {
  const { t } = useTranslation();

  const selectedItem =
    options?.length && selectedValue
      ? (options.find(({ id }) => id === selectedValue) as OrganisationResponse)
      : null;
  const [inputValue, setInputValue] = useState<string>('');

  const typeTranslations: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.type.customer'),
    PARTNER: t('ui.event-management.organisations.type.partner'),
  };

  return (
    <>
      <Autocomplete
        className={className}
        id="organisation-search"
        data-testid={dataTestId || 'organisation-autocomplete'}
        disableClearable={disableClearable}
        autoSelect={disableClearable}
        options={options}
        fullWidth
        loadingText={t('ui.event-management.organisations.loading-text')}
        noOptionsText={t('ui.event-management.organisations.no-options-text')}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onInputChange={(e: any) => setInputValue(e?.target?.value || '')}
        inputValue={inputValue}
        onChange={(_, value, reason) => {
          if (
            reason === ('selectOption' as AutocompleteChangeReason) &&
            value !== null
          ) {
            onSelect(value);

            if (clearOnSelect) {
              setInputValue('');
              onSelect({
                id: '',
                name: '',
                dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
              });
            } else {
              setInputValue(value.name);
            }
          }
        }}
        filterOptions={createFilterOptions({
          stringify: (option: OrganisationResponse) => `${option.name}`,
        })}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(fullProps, val) => {
          const { color, ...rest } = fullProps;
          return (
            <StyledListItem
              {...rest}
              key={val.id}
              data-testid={`organisation-option-${val.id}`}
            >
              <StyledP
                sx={{ textAlign: 'left' }}
                variant="body-bold"
                color="primary"
              >
                {val.name}
              </StyledP>

              {organisationsInGroups[val.id] ? (
                <StyledP sx={{ textAlign: 'right' }} color="textSecondary">
                  <GroupText
                    groupName={organisationsInGroups[val.id].name}
                    groupType={
                      typeTranslations[organisationsInGroups[val.id].type]
                    }
                  />
                </StyledP>
              ) : null}
            </StyledListItem>
          );
        }}
        value={selectedItem}
        loading={loading}
        renderInput={(params: TextFieldParamsProps) => {
          const { size, id, ...rest } = params;
          const { ref } = rest.InputProps;
          const { children, ...props } = rest.inputProps;
          return (
            <div ref={ref}>
              <StyledInput
                {...props}
                id={id}
                label={t(labelKey)}
                placeholder={t(
                  'ui.event-management.organisations.select-organisation-placeholder',
                )}
                required={!!required}
                error={error}
                disabled={!!disabled}
                data-testid={`${dataTestId}-input`}
                type={'text' as InputType}
                sx={inputSx}
                onKeyDown={preventFormSubmission}
              />
            </div>
          );
        }}
      />
      {helperText ? <ErrorMessage>{helperText}</ErrorMessage> : null}
    </>
  );
};

export default OrganisationAutocomplete;
