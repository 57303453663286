import {
  _delete,
  get,
  post,
  put,
} from '@insights-ltd/design-library/src/utils/mocking';
import {
  DfcInviteeResponse,
  EventResponseWithChapters,
  EventSummaryResponse,
  EventReminderResponse,
  Languages,
  RequestErrorBody,
  GetContributorsResponse,
  WalletResponse,
} from 'api';
import { InviteesResponse, ScoresResponse } from 'api/events/eventApiTypes';
import { API_ROOT } from 'mocks/handlers/common';
import { Model } from 'variables';

export const events = {
  all: get<{ events: EventSummaryResponse[] } | undefined>(
    `${API_ROOT}/events`,
  ),
  get: (eventId: string) =>
    get<EventResponseWithChapters | undefined>(`${API_ROOT}/events/${eventId}`),
  update: (eventId: string) => put<undefined>(`${API_ROOT}/events/${eventId}`),
  cancel: (eventId: string) =>
    post<undefined>(`${API_ROOT}/events/${eventId}/cancellation`),
  delete: (eventId: string) =>
    _delete<undefined>(`${API_ROOT}/events/${eventId}`),
  contributors: {
    all: (eventId: string) =>
      get<
        | GetContributorsResponse
        | {
            invitees: DfcInviteeResponse[];
          }
        | undefined
      >(`${API_ROOT}/events/${eventId}/contributors`),
    addToAll: (eventId: string, learnerId: string) =>
      post<undefined>(
        `${API_ROOT}/events/${eventId}/contributors/invitee/${learnerId}/contributors`,
      ),
  },
  invitees: {
    all: (eventId: string) =>
      get<
        | InviteesResponse
        | {
            invitees: DfcInviteeResponse[];
          }
        | undefined
      >(`${API_ROOT}/events/${eventId}/invitees`),
    create: (eventId: string) =>
      post<undefined | RequestErrorBody>(
        `${API_ROOT}/events/${eventId}/invitees`,
      ),
    sendInvites: (eventId: string) =>
      post<undefined>(`${API_ROOT}/events/${eventId}/invitees/invite/schedule`),
    sendAllInvites: (eventId: string) =>
      post<undefined>(
        `${API_ROOT}/events/${eventId}/invitees/invite/schedule/all`,
      ),
    update: (eventId: string, inviteeId: string) =>
      put<undefined>(`${API_ROOT}/events/${eventId}/invitees/${inviteeId}`),
    delete: (eventId: string, inviteeId: string) =>
      _delete<undefined>(`${API_ROOT}/events/${eventId}/invitees/${inviteeId}`),
    chapters: {
      update: (eventId: string) =>
        put<undefined>(`${API_ROOT}/events/${eventId}/invitees/chapters`),
    },
  },
  profiles: {
    purchase: (eventId: string) =>
      post<undefined | RequestErrorBody>(
        `${API_ROOT}/events/${eventId}/profiles/purchase`,
      ),
    download: (eventId: string) =>
      post<undefined>(`${API_ROOT}/events/${eventId}/profiles/download`),
    email: (eventId: string) =>
      post<undefined>(`${API_ROOT}/events/${eventId}/profiles/emails`),
    downloadLinkEmail: (id: string) =>
      post<undefined>(`${API_ROOT}/events/${id}/profiles/email/me`),
  },
  scores: {
    all: (eventId: string) =>
      get<ScoresResponse>(`${API_ROOT}/events/${eventId}/invitees/scores`),
  },
  reminders: {
    all: (eventId: string) =>
      get<EventReminderResponse | undefined>(
        `${API_ROOT}/events/${eventId}/reminders`,
      ),
    create: (eventId: string) =>
      post<EventReminderResponse>(`${API_ROOT}/events/${eventId}/reminders`),
    cancel: (eventId: string) =>
      _delete<undefined>(`${API_ROOT}/events/${eventId}/reminders`),
  },
  wallet: {
    get: (practitionerId: string) =>
      get<WalletResponse | undefined | RequestErrorBody>(
        `${API_ROOT}/practitioners/${practitionerId}/wallet`,
      ),
  },
  supportedDialects: {
    all: (model: Model) =>
      get<Languages | undefined>(`${API_ROOT}/dialects/${model}`),
  },
};
