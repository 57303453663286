import {
  spacingSizeMap,
  P,
  passwordValidationRules,
  H1,
  TextInputV2,
  Select,
  Span,
} from '@insights-ltd/design-library';
import SvgArrowLeftV2 from '@insights-ltd/design-library/src/components/Svgs/streamline-regular/ArrowLeftV2';
import {
  FormControl,
  Button,
  Snackbar,
  Card,
  CardContent,
  Grid,
  Alert,
  styled,
  Link,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  ButtonBase,
  Theme,
} from '@mui/material';
import ValidationTags from 'components/ValidationTags';
import { NO_SPECIAL_CHARACTERS_REGEX } from 'variables';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Control, Controller, useForm } from 'react-hook-form';
import { RequestError } from 'api';
import {
  isPreferredDialect,
  useGetApplicationPreferredDialects,
} from 'domain/dialect';
import SignUpLanguageSelection from 'components/SignUpLanguageSelection/SignUpLanguageSelection';
import { useBetaEnabled } from 'features';
import { Dialects, PreferredDialect } from 'types/dialects';
import { getPrivacyPolicyUrl } from 'utils/generalUtils';
import { FormData } from '../types';

const PolicyAcceptanceCheckbox = ({
  id,
  name,
  label,
  control,
  error,
  errorText,
}: {
  id: string;
  name: keyof FormData;
  label: React.ReactNode;
  control: Control<FormData>;
  error: boolean;
  errorText: string;
}) => {
  const errorLabelId = `${id}-error-label`;
  return (
    <FormControl
      sx={(theme) => ({
        width: '100%',
        margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
      })}
      required
      error={error}
    >
      <FormControlLabel
        control={
          <Controller
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                id={id}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                checked={Boolean(value)}
                name={name}
                color="primary"
                inputProps={{
                  'aria-describedby': errorLabelId,
                }}
              />
            )}
            name={name}
            control={control}
            rules={{ required: true }}
            defaultValue={false}
          />
        }
        label={label}
      />
      {error && <FormHelperText id={errorLabelId}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

const TermsAndConditionsCheckboxLabel = ({
  preferredDialect = 'en-GB',
}: {
  preferredDialect: Dialects | undefined;
}) => {
  return (
    <Trans i18nKey="ui.event-management.sign-up.terms-conditions">
      <Link
        href={getPrivacyPolicyUrl(preferredDialect)}
        target="_blank"
        rel="noopener"
      >
        ui.event-management.sign-up.terms-conditions
      </Link>
    </Trans>
  );
};

type Props = {
  onSubmit: (data: FormData) => void;
  isLoading?: boolean;
  signUpError?: RequestError | null;
};

const StyledH1 = styled(H1)(({ theme }) => ({
  marginBottom: `${theme.spacing(spacingSizeMap.XS)} !important`,
}));

const StyledTextSxProps = (theme: Theme) => ({
  width: '100%',
  '& > div': {
    width: '100%',
  },
  input: {
    width: '100% !important',
  },
  margin: `0 0 ${theme.spacing(spacingSizeMap.XS)} 0`,
});

const StyledIcon = styled(SvgArrowLeftV2)(({ theme }) => ({
  transform: 'scale(0.5)',
  stroke: theme.palette.primary.main,
  strokeWidth: '2',
}));

const SignUpForm = ({ onSubmit, isLoading, signUpError }: Props) => {
  const { t, i18n } = useTranslation();
  const isBetaEnabled = useBetaEnabled('supported-languages');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    resetField,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const [showPassword, preferredDialect] = watch([
    'showPassword',
    'preferredDialect',
  ]);

  const handleSetPreferredDialect = (dialect?: PreferredDialect) => {
    if (!dialect && resetField) {
      resetField('preferredDialect');
      i18n.changeLanguage('en-GB');
      if (reset) {
        reset();
      }
    } else if (setValue) {
      if (isPreferredDialect(dialect, isBetaEnabled)) {
        i18n.changeLanguage(dialect);
      } else {
        i18n.changeLanguage('en-GB');
      }
      setValue('preferredDialect', dialect);
    }
  };

  const genericErrors: Record<number, string> = {
    404: 'ui.event-management.authentication.errors.token',
  };

  const dialectOptions = useGetApplicationPreferredDialects(isBetaEnabled);
  const { ref: passwordRef, ...passwordFormProps } = register(
    'password',
    passwordValidationRules,
  );
  const { ref: fullNameRef, ...fullNameProps } = {
    ...register('fullName', {
      required: true,
      pattern: NO_SPECIAL_CHARACTERS_REGEX,
    }),
  };

  const { ref: preferredDialectRef, ...preferredDialectProps } = {
    ...register('preferredDialect', {
      required: true,
    }),
  };

  const specificErrors: Record<string, string> = {
    PASSWORD_INVALID:
      'ui.event-management.authentication.errors.invalid-password',
    INVITE_EXPIRED_OR_USED: 'ui.event-management.authentication.errors.token',
  };

  const fullNameHelperText =
    errors.fullName?.type === 'required'
      ? t('ui.event-management.sign-up.full-name.error.required')
      : errors.fullName?.type === 'pattern' &&
        t(
          'ui.event-management.my-account.edit.error.value-full-name-no-special-characters',
        );

  const isUnsupportedPreferredDialect = !isPreferredDialect(preferredDialect);

  return !preferredDialect ? (
    <SignUpLanguageSelection
      onSelect={(dialect: PreferredDialect) => {
        handleSetPreferredDialect(dialect);
      }}
    />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={(theme) => ({
          maxWidth: '500px',
          padding: `${theme.spacing(spacingSizeMap.L)} ${theme.spacing(
            spacingSizeMap.L,
          )} 0`,
        })}
      >
        <CardContent sx={{ padding: '0' }}>
          <Grid
            spacing={1}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                paddingTop: '0 !important',
                paddingLeft: '2px !important',
                marginBottom: theme.spacing(spacingSizeMap.XS),
              })}
            >
              <ButtonBase
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  handleSetPreferredDialect();
                }}
              >
                <StyledIcon />
                <Span color="primary" variant="body-bold">
                  {t('ui.event-management.sign-up.back')}
                </Span>
              </ButtonBase>
            </Grid>
            <Grid
              sx={(theme) => ({
                width: '100%',
                margin: `0 0 ${theme.spacing(spacingSizeMap.XS)}`,
                paddingLeft: theme.spacing(spacingSizeMap.XS),
              })}
              item
              xs={12}
            >
              <StyledH1 variant="h3">
                {t('ui.event-management.sign-up.heading')}
              </StyledH1>
              <P variant="body">
                {t('ui.event-management.sign-up.instruction.beta')}
              </P>
            </Grid>
            <Grid
              item
              sx={(theme) => ({
                marginBottom: theme.spacing(spacingSizeMap.XS),
              })}
              xs={12}
            >
              <Select
                id="preferredDialect"
                innerRef={preferredDialectRef}
                label={t(
                  'ui.event-management.sign-up.preferred-language.input-label',
                )}
                type="text"
                variant="outlined"
                helperText={t(
                  isUnsupportedPreferredDialect
                    ? 'ui.event-management.my-account.edit.preferred-language.supported-language'
                    : 'ui.event-management.sign-up.preferred-language.helper-text',
                )}
                sx={{
                  width: '100%',
                  '& > div': {
                    width: '100%',
                  },
                  input: {
                    width: '100% !important',
                  },
                  fieldset: {
                    borderColor: 'transparent !important',
                  },
                }}
                {...preferredDialectProps}
                disabled
                items={dialectOptions}
                value={watch('preferredDialect')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputV2
                id="fullName"
                data-testid="full-name-input"
                labelText={t(
                  'ui.event-management.sign-up.full-name.input-label',
                )}
                placeholder={t(
                  'ui.event-management.sign-up.full-name.input-placeholder',
                )}
                sx={StyledTextSxProps}
                type="text"
                variant="outlined"
                error={Boolean(errors.fullName)}
                helperText={fullNameHelperText}
                innerRef={fullNameRef}
                autoComplete="off"
                {...fullNameProps}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                '> p': {
                  marginBottom: theme.spacing(spacingSizeMap.S),
                },
              })}
            >
              <P variant="body" color="textSecondary">
                {t('ui.event-management.authentication.password.instruction')}
              </P>
              <ValidationTags
                sx={{ marginBottom: '0rem' }}
                value={watch('password') || ''}
                errors={errors.password}
              />
              <FormControl
                variant="outlined"
                sx={(theme) => ({
                  width: '100%',
                  margin: `${theme.spacing(spacingSizeMap.XS)} 0 0`,
                  '> div': {
                    marginBottom: '0',
                  },
                })}
              >
                <TextInputV2
                  data-testid="password-input"
                  id="password"
                  autoComplete="off"
                  labelText={t(
                    'ui.event-management.sign-up.password.input-label',
                  )}
                  placeholder={t(
                    'ui.event-management.sign-up.password.input-placeholder',
                  )}
                  type={showPassword ? 'text' : 'password'}
                  innerRef={passwordRef}
                  {...passwordFormProps}
                  error={Boolean(errors.password)}
                  sx={StyledTextSxProps}
                />
              </FormControl>
            </Grid>
            <Grid
              sx={(theme) => ({
                paddingLeft: theme.spacing(spacingSizeMap.XS),
              })}
            >
              <FormControlLabel
                sx={(theme) => ({
                  '& span': {
                    fontWeight: theme.typography.fontWeightBold,
                  },
                })}
                control={
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        id="show-password"
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                        checked={Boolean(value)}
                        name="show-password"
                        color="primary"
                        inputProps={{
                          'aria-describedby': 'show-password',
                        }}
                      />
                    )}
                    name="showPassword"
                    control={control}
                    defaultValue={false}
                  />
                }
                label={t(
                  'ui.event-management.sign-up.show-password.input-label',
                )}
              />
            </Grid>
            <Grid
              sx={(theme) => ({
                '& span': {
                  fontWeight: theme.typography.fontWeightBold,
                },
                '> div': {
                  display: 'flex',
                  alignItems: 'flex-start',
                },
                paddingLeft: theme.spacing(spacingSizeMap.XS),
              })}
            >
              <PolicyAcceptanceCheckbox
                id="accept-terms"
                name="acceptTerms"
                label={
                  <TermsAndConditionsCheckboxLabel
                    preferredDialect={preferredDialect}
                  />
                }
                control={control}
                error={Boolean(errors.acceptTerms)}
                errorText={t(
                  'ui.event-management.sign-up.terms-conditions.error.required',
                )}
              />
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={isLoading}
              >
                {t('ui.event-management.sign-up.cta')}
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={signUpError !== null}
            autoHideDuration={6000}
            sx={{ flexDirection: 'column' }}
          >
            {signUpError ? (
              <div>
                {signUpError
                  .getRequestErrorKeys(
                    'ui.event-management.authentication.errors.general',
                    genericErrors,
                    specificErrors,
                  )
                  .map((key) => (
                    <Alert
                      key={key}
                      elevation={6}
                      variant="filled"
                      severity="error"
                    >
                      {t(key)}
                    </Alert>
                  ))}
              </div>
            ) : undefined}
          </Snackbar>
        </CardContent>
      </Card>
    </form>
  );
};

export default SignUpForm;
