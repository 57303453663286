import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  AddContributorRequest,
  EventReminderResponse,
  EventResponseWithChapters,
  EventSummaryResponse,
  GetContributorsInvitees,
  InviteeBulkResult,
  InviteeRequest,
  InviteeResponse,
  RequestError,
  Score,
} from 'api/httpEntities';
import { Model, QUERY_KEY_ROOTS } from 'variables';
import { QueryOptionsConfig } from 'api/hooks';
import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';
import { sortChapters } from 'utils/mappers/sorting';
import { localTime } from '@insights-ltd/design-library';
import { DateTime } from 'luxon';
import {
  DeleteInviteeData,
  DownloadEventProfilesRequest,
  EmailEventProfilesRequest,
  GetEvents,
  InviteeChaptersRequest,
  InviteeMutationData,
  UpdateEventData,
  UpdateInviteeData,
} from './eventApiTypes';
import {
  addContributor,
  addInvitee,
  addLearnerToAll,
  cancelEvent,
  deleteEvent,
  deleteInvitee,
  downloadProfilesZIPForEvent,
  emailEventProfiles,
  getAllEvents,
  getContributors,
  getEvent,
  getEventInvitees,
  getEventReminder,
  getEventTeamScores,
  getSupportedDialects,
  Languages,
  purchaseProfilesForEvent,
  removeContributor,
  sendAllEventInvites,
  resendEventInvites,
  setEventReminder,
  updateEvent,
  updateInvitee,
  updateInviteeChapters,
  downloadProfilesViaEmail,
  cancelEventReminder,
} from './events';

export type ExperienceViewType = 'active' | 'archived' | 'cancelled';

export const useGetSupportedDialects = (
  model: Model,
  dialect?: string,
): UseQueryResult<Languages, unknown> =>
  useQuery({
    queryKey: ['languages', model, dialect],
    queryFn: async () => getSupportedDialects(model, dialect),
  });

export const useEmailEventProfiles = (): UseMutationResult<
  boolean,
  unknown,
  EmailEventProfilesRequest,
  unknown
> => useMutation({ mutationFn: emailEventProfiles });

export const useUpdateEvent = (
  queryClient: QueryClient,
): UseMutationResult<void, unknown, UpdateEventData, RequestError> =>
  useMutation({
    mutationFn: updateEvent,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.EVENT_DATA],
      }),
  });

export const useGetAllEvents = (
  { status, from, to }: GetEvents,
  config: QueryOptionsConfig<EventSummaryResponse[]> = {},
): UseQueryResult<EventSummaryResponse[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.EVENTS_DATA, status, from, to] as QueryKey,
    queryFn: async () => getAllEvents({ status, from, to }),
    ...config,
  });

export const useGetEvents = (
  viewType: ExperienceViewType,
  config: QueryOptionsConfig<EventSummaryResponse[]> = {},
): UseQueryResult<EventSummaryResponse[], Error> => {
  const status = viewType === 'cancelled' ? 'CANCELLED' : 'ACTIVE';
  const to =
    viewType === 'archived'
      ? DateTime.fromISO(localTime.toString(), { zone: 'UTC' }).toString()
      : undefined;
  const from =
    viewType === 'active'
      ? DateTime.fromISO(localTime.toString(), { zone: 'UTC' }).toString()
      : undefined;
  return useGetAllEvents({ status, from, to }, config);
};

export const useEventInvitees = (
  eventId: string,
  config: QueryOptionsConfig<InviteeResponse[]> = {},
): UseQueryResult<InviteeResponse[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA, eventId] as QueryKey,
    queryFn: async () => {
      const { invitees } = await getEventInvitees(eventId);
      return invitees.map(({ chapters, ...invitee }) => {
        const sortedChapters = chapters.sort(
          ({ name: nameA }, { name: nameB }) => sortChapters(nameA, nameB),
        );

        return {
          ...invitee,
          chapters: sortedChapters,
        };
      });
    },
    ...config,
  });

export const useGetInviteeContributors = (
  eventId: string,
): UseQueryResult<LearnerContributors[], RequestError> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA, eventId],
    queryFn: async () => {
      const { invitees } = await getEventInvitees(eventId);
      let contributorsData = [] as GetContributorsInvitees;
      if (invitees?.length) {
        const { invitees: inviteeContributors } =
          await getContributors(eventId);
        contributorsData = inviteeContributors;
      }

      return contributorsData.reduce<LearnerContributors[]>(
        (acc, { id, contributorInvitees }) => {
          const learner = invitees.find((invitee) => invitee.id === id);
          if (learner) {
            return [...acc, { learner, contributors: contributorInvitees }];
          }
          return acc;
        },
        [],
      );
    },
  });

export const useGetEvent = (
  eventId: string,
  config: QueryOptionsConfig<EventResponseWithChapters> = {},
): UseQueryResult<EventResponseWithChapters, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.EVENT_DATA, eventId] as QueryKey,
    queryFn: async () => getEvent(eventId),
    ...config,
  });

export const useDeleteEvent = (
  queryClient: QueryClient,
): UseMutationResult<void, Error, { eventId: string }, unknown> =>
  useMutation({
    mutationFn: deleteEvent,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.EVENTS_DATA],
      }),
  });

export const useCancelEvent = (
  queryClient: QueryClient,
): UseMutationResult<
  void,
  Error,
  { eventId: string; refundedPractitioner?: string },
  unknown
> =>
  useMutation({
    mutationFn: cancelEvent,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.EVENTS_DATA],
      }),
  });

export const useSendInvites = (): UseMutationResult<
  void,
  Error,
  { eventId: string; inviteeIds?: string[] },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId, inviteeIds }) => {
      return inviteeIds?.length
        ? resendEventInvites({ eventId, inviteeIds })
        : sendAllEventInvites({ eventId });
    },

    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEY_ROOTS.EVENT_DATA],
        })
        .then();
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
        })
        .then();
    },
  });
};

export const useAddInvitees = (
  eventId: string,
): UseMutationResult<InviteeBulkResult[], Error, InviteeRequest[], unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (invitees: InviteeRequest[]) =>
      Promise.all(
        invitees.map(async (formData) => {
          try {
            await addInvitee({ eventId, formData });
            return {
              type: 'SUCCESS',
              inviteeData: formData,
            } as InviteeBulkResult;
          } catch (error) {
            if (error instanceof Error) {
              return {
                type: 'FAILURE',
                inviteeData: formData,
                error,
              } as InviteeBulkResult;
            }
            throw error;
          }
        }),
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      }),
  });
};

export const useAddInvitee = (): UseMutationResult<
  void,
  Error,
  InviteeMutationData,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addInvitee,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA],
      });
    },
  });
};

export const useUpdateInvitee = (
  queryClient: QueryClient,
): UseMutationResult<void, unknown, UpdateInviteeData, unknown> =>
  useMutation({
    mutationFn: updateInvitee,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      }),
  });

export const useDeleteInvitee = (): UseMutationResult<
  void,
  unknown,
  DeleteInviteeData,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteInvitee,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA],
      });
    },
  });
};

export const useUpdateInviteeChapters = (
  queryClient: QueryClient,
): UseMutationResult<Response, Error, InviteeChaptersRequest, Error> =>
  useMutation({
    mutationFn: updateInviteeChapters,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      }),
  });

export const useGetEventTeamScores = (
  eventId: string,
  config: QueryOptionsConfig<Score[]> = {},
): UseQueryResult<Score[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.EVENT_TEAM_SCORES_DATA, eventId] as QueryKey,
    queryFn: async () => {
      const { scores } = await getEventTeamScores(eventId);
      return scores;
    },
    ...config,
  });

export const useDownloadEventProfiles = (): UseMutationResult<
  boolean,
  unknown,
  DownloadEventProfilesRequest,
  unknown
> => useMutation({ mutationFn: downloadProfilesZIPForEvent });

export const useSendProfileLinkToPractitioner = () => {
  return useMutation({ mutationFn: downloadProfilesViaEmail });
};

export const usePurchaseEventProfiles = (
  queryClient: QueryClient,
): UseMutationResult<
  boolean,
  unknown,
  { eventId: string; inviteeIds: string[] },
  unknown
> =>
  useMutation({
    mutationFn: purchaseProfilesForEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.INVITEES_DATA],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA],
      });
    },
  });

export const useGetEventReminder = (
  eventId: string,
  config: QueryOptionsConfig<EventReminderResponse> = {},
): UseQueryResult<EventReminderResponse, Error> =>
  useQuery<EventReminderResponse, Error>({
    queryKey: [QUERY_KEY_ROOTS.EVENT_REMINDER_DATA, eventId],
    queryFn: async () => getEventReminder(eventId),
    ...config,
  });

export const useSetEventReminder = (
  eventId: string,
): UseMutationResult<
  EventReminderResponse,
  Error,
  { eventId: string; reminderAt: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setEventReminder,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.EVENT_REMINDER_DATA, eventId],
      }),
  });
};

export const useCancelEventReminder = (
  eventId: string,
): UseMutationResult<void, Error, { eventId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: cancelEventReminder,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.EVENT_REMINDER_DATA, eventId],
      }),
  });
};

export const useGetContributors = (
  eventId: string,
  invitees: InviteeResponse[],
  enabled: boolean,
): UseQueryResult<LearnerContributors[], Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA, eventId],
    queryFn: async () => {
      const { invitees: contributorsData } = await getContributors(eventId);

      return contributorsData.reduce<LearnerContributors[]>(
        (acc, { id, contributorInvitees }) => {
          const learner = invitees.find((invitee) => invitee.id === id);
          if (learner) {
            return [...acc, { learner, contributors: contributorInvitees }];
          }
          return acc;
        },
        [],
      );
    },
    enabled,
  });

export const useAddContributor = (
  eventId: string,
): UseMutationResult<void, RequestError, AddContributorRequest> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (contributor) => addContributor(eventId, contributor),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA, eventId],
      });
    },
  });
};

export const useRemoveContributor = (
  eventId: string,
): UseMutationResult<void, Error, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (contributorId) => removeContributor(eventId, contributorId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA, eventId],
      });
    },
  });
};

type AddLearnerArgs = {
  learnerId: string;
  inviteeIds: string[];
};

export const useAddLearnerToAll = (
  eventId: string,
): UseMutationResult<void, RequestError, AddLearnerArgs> => {
  const queryClient = useQueryClient();
  return useMutation<void, RequestError, AddLearnerArgs>({
    mutationFn: ({ learnerId, inviteeIds }) =>
      addLearnerToAll(eventId, learnerId, inviteeIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.CONTRIBUTORS_DATA, eventId],
      });
    },
  });
};
