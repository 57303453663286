import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgSingleNeutral from '@insights-ltd/design-library/src/components/Svgs/streamline-bold/SingleNeutralV2';
import { P } from '@insights-ltd/design-library';
import SectionHeader from 'components/SectionHeader';
import { PractitionerData } from 'api';
import { ROLES } from 'variables';
import { sortRoles } from 'utils/mappers/sorting';
import { Role } from 'types/types';
import { styled } from '@mui/material';
import LinkEmail from 'components/LinkEmail/LinkEmail';
import { useGetApplicationPreferredDialects } from 'domain/dialect';
import { useBetaEnabled } from 'features';

const roleDescription = (roles: Role[]) => {
  return ROLES[(roles ?? []).sort(sortRoles)[0]];
};

const StyledIcon = styled(SvgSingleNeutral)({
  height: '20px',
  width: '20px',
});

const UserInfoSection = ({
  emailAddress,
  practitioner,
}: {
  emailAddress: string;
  practitioner?: PractitionerData;
}) => {
  const { t } = useTranslation();
  const role = t(roleDescription(practitioner?.roles ?? ['STANDARD']));
  const isBetaEnabled = useBetaEnabled('supported-languages');

  const preferredDialect =
    useGetApplicationPreferredDialects(isBetaEnabled)[
      practitioner?.preferredDialect ?? 'en-GB'
    ];

  return (
    <>
      <SectionHeader
        icon={<StyledIcon />}
        title={t('ui.event-management.my-account.information')}
      />
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <P variant="body-bold" color="textSecondary">
          {t('ui.event-management.my-account.email-address')}
        </P>
        <LinkEmail sx={{ maxWidth: 'unset' }} email={emailAddress} />
      </Grid>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ marginTop: '12px' }}
      >
        <P variant="body-bold" color="textSecondary">
          {t('ui.event-management.my-account.role')}
        </P>
        <P variant="body-bold">{role}</P>
      </Grid>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ marginTop: '12px' }}
      >
        <P variant="body-bold" color="textSecondary">
          {t('ui.event-management.my-account.preferred-language')}
        </P>
        <P variant="body-bold">{t(preferredDialect)}</P>
      </Grid>
      <Box mb="40px" />
    </>
  );
};

export default UserInfoSection;
