import {
  EvaluationsProfilesResponse,
  EventSummaryResponse,
  LearnerAndInviteeResponse,
  OrganisationResponse,
  OrganisationSupportedRole,
  PractitionerSummaryData,
  RequestErrorBody,
  SupportedRolesResponse,
} from 'api/httpEntities';
import {
  _delete,
  get,
  post,
  put,
} from '@insights-ltd/design-library/src/utils/mocking';
import { PractitionersHttpResponse } from 'test/types';
import { OpenPractitionerInvitesResponse } from 'api/organisations/organisationApitypes';
import { API_ROOT } from './common';

export const organisations = {
  all: get<{ organisations: OrganisationResponse[] } | undefined>(
    `${API_ROOT}/organisations`,
  ),
  create: post<OrganisationResponse | RequestErrorBody>(
    `${API_ROOT}/organisations`,
  ),
  get: (organisationId: string) =>
    get<OrganisationResponse | undefined>(
      `${API_ROOT}/organisations/${organisationId}`,
    ),
  update: (organisationId: string) =>
    put<OrganisationResponse | RequestErrorBody>(
      `${API_ROOT}/organisations/${organisationId}`,
    ),
  practitioners: {
    all: (organisationId: string) =>
      get<
        | PractitionersHttpResponse
        | { practitioners: PractitionerSummaryData[] }
        | undefined
        | RequestErrorBody
      >(`${API_ROOT}/organisations/${organisationId}/practitioners`),
    delete: (organisationId: string, practitionerId: string) =>
      _delete<RequestErrorBody | undefined>(
        `${API_ROOT}/organisations/${organisationId}/practitioners/${practitionerId}`,
      ),
    invites: {
      create: (organisationId: string) =>
        post<undefined>(
          `${API_ROOT}/organisations/${organisationId}/practitioners/invites`,
        ),
      all: (organisationId: string) =>
        get<OpenPractitionerInvitesResponse | undefined>(
          `${API_ROOT}/organisations/${organisationId}/practitioners/invites`,
        ),
    },
  },
  events: {
    all: (organisationId: string) =>
      get<{ events: EventSummaryResponse[] }>(
        `${API_ROOT}/organisations/${organisationId}/events`,
      ),
    create: (organisationId: string) =>
      post<{ id: string } | RequestErrorBody>(
        `${API_ROOT}/organisations/${organisationId}/events`,
      ),
  },
  learners: {
    all: (organisationId: string) =>
      get<LearnerAndInviteeResponse | undefined>(
        `${API_ROOT}/organisations/${organisationId}/learners`,
      ),
  },
  transferLearner: (
    sourceOrganisationId: string,
    learnerId: string,
    targetOrganisationId: string,
  ) =>
    put<undefined | RequestErrorBody>(
      `${API_ROOT}/organisations/${sourceOrganisationId}/learners/${learnerId}/transfer-learner/${targetOrganisationId}`,
    ),
  evaluations: {
    search: (organisationId: string) =>
      post<EvaluationsProfilesResponse>(
        `${API_ROOT}/organisations/${organisationId}/evaluations/searches`,
      ),
  },
  teams: {
    addToOrganisation: (organisationId: string) =>
      post<{ id: string }>(`${API_ROOT}/organisations/${organisationId}/teams`),
  },
  supportedRoles: {
    get: (organisationId: string) =>
      get<SupportedRolesResponse | undefined>(
        `${API_ROOT}/organisations/${organisationId}/supportedRoles`,
      ),
    all: () =>
      get<
        { organisationSupportedRoles: OrganisationSupportedRole[] } | undefined
      >(`${API_ROOT}/organisations/supported-roles`),
  },
};
